import "./style.css";

const SideBar = (props) => {
  return (
    <div className="sidebar-container">
      <h1>Machines</h1>
      <ul className="machine-list">
        <li key="Overview" onClick={props.onClick}>
          <a href="#">Overview</a>
        </li>
        {props?.elements?.map((machine) => (
          <li key={machine.machineName} onClick={props.onClick}>
            <a href="#">{machine.machineName}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
