import { useEffect } from "react";
import "./style.css";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

const PieceCount = (props) => {
  const getLatestProductionState = (machineData) => {
    machineData.sort(function (a, b) {
      return b.dateTimeDE - a.dateTimeDE;
    });

    return machineData[0].pieces;
  };

  const getLastProduction = (data) => {
    data.sort(function (a, b) {
      return b.dateTimeDE - a.dateTimeDE;
    });
    return data[0].pieces - data[24].pieces;
  };

  const getTrend = (machineData) => {
    try {
      machineData.sort(function (a, b) {
        return b.dateTimeDE - a.dateTimeDE;
      });
      var last, prev;
      last = machineData[0].pieces - machineData[24].pieces;
      prev = machineData[24].pieces - machineData[48].pieces;

      // console.log(last);
      // console.log(prev);
      const fac = last / prev;
      return Math.round((fac - 1) * 100);
    } catch (err) {
      //console.log(err);
      return null;
    }
  };

  const getTrendIcon = (machineData) => {
    var trend = getTrend(machineData);

    if (trend > 0) {
      return "./res/up.jpg";
    } else if (trend < 0) {
      return "./res/down.jpg";
    } else {
      return "./res/even.jpg";
    }
  };

  return props?.machineData?.length ? (
    <div className="piececount-view-container">
      <h1>{props.pieceCountTypeName}</h1>
      <div className="piececount-data-container">
        {props.pieceCountTypeName === "Absolute Piece Count" ? (
          <>
            <p className="piececount">
              {numberWithCommas(getLatestProductionState(props?.machineData))}
            </p>
            <ul>
              <li></li>
            </ul>
          </>
        ) : (
          <>
            <p className="piececount">
              {numberWithCommas(getLastProduction(props?.machineData))}
            </p>
            <ul>
              <li>
                <div className="arrow-container">
                  <img src={getTrendIcon(props?.machineData)} />
                </div>
              </li>
              <li>{getTrend(props?.machineData)}</li>
              <li>{"% compared to yesterday"}</li>
            </ul>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="piececount-view-container">
      <h1>There is no data to show</h1>
    </div>
  );
};

export default PieceCount;
