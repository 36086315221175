import React, { Component } from "react";
import useAuth from "../../../hooks/useAuth";

import "./style.css";

const Home = () => {
  const { auth } = useAuth();
  return (
    <section className="home">
      <div className="head_img_container">
        <img className="head-img" src="./res/hinterkopf-D360-3.jpg" />
        <div className="title-text">
          <p className="title-text-1">DigiStats.</p>
          <p className="title-text-2">Platform&#169;</p>
          {auth.user ? (
            <p></p>
          ) : (
            <p className="title-text-3">Please login to continue...</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Home;
