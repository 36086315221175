import "./style.css";

const DropoutButton = (props) => {
  return (
    <button className="dropout-btn" onClick={props.onClick}>
      <svg id="in-svg" viewBox="0 0 487.20288 679.84852">
        <g
          id="g30"
          transform="matrix(11.515379,0,0,18.810601,-3998.8148,-4904.2481)"
        >
          <path
            className="cls-1"
            d="m 354.6,296.4 c -2.05,0 -4,-1.21 -4.84,-3.22 -1.13,-2.67 0.13,-5.75 2.8,-6.88 l 17.85,-7.51 -17.85,-7.51 c -2.67,-1.13 -3.93,-4.2 -2.8,-6.88 1.12,-2.67 4.2,-3.93 6.88,-2.8 l 29.34,12.35 c 1.95,0.82 3.21,2.73 3.21,4.84 0,2.11 -1.27,4.02 -3.21,4.84 l -29.34,12.35 c -0.67,0.28 -1.36,0.41 -2.04,0.41 z"
            id="path29"
          />
          <path
            className="cls-1"
            d="m 389.2,278.8 c 0,2.11 -1.27,4.02 -3.21,4.84 l -29.34,12.35 c -0.67,0.28 -1.36,0.41 -2.04,0.41 -2.05,0 -4,-1.21 -4.84,-3.22 -1.13,-2.67 0.13,-5.75 2.8,-6.88 l 17.85,-7.51 h 18.78 z"
            id="path30"
          />
        </g>
      </svg>
      <svg id="out-svg" viewBox="0 0 458.94189 662.40955">
        <g
          id="g30"
          transform="matrix(-11.515379,0,0,-18.810601,4481.7855,5575.4621)"
        >
          <path
            className="cls-1"
            d="m 354.6,296.4 c -2.05,0 -4,-1.21 -4.84,-3.22 -1.13,-2.67 0.13,-5.75 2.8,-6.88 l 17.85,-7.51 -17.85,-7.51 c -2.67,-1.13 -3.93,-4.2 -2.8,-6.88 1.12,-2.67 4.2,-3.93 6.88,-2.8 l 29.34,12.35 c 1.95,0.82 3.21,2.73 3.21,4.84 0,2.11 -1.27,4.02 -3.21,4.84 l -29.34,12.35 c -0.67,0.28 -1.36,0.41 -2.04,0.41 z"
            id="path29"
          />
          <path
            className="cls-1"
            d="m 389.2,278.8 c 0,2.11 -1.27,4.02 -3.21,4.84 l -29.34,12.35 c -0.67,0.28 -1.36,0.41 -2.04,0.41 -2.05,0 -4,-1.21 -4.84,-3.22 -1.13,-2.67 0.13,-5.75 2.8,-6.88 l 17.85,-7.51 h 18.78 z"
            id="path30"
          />
        </g>
      </svg>
    </button>
  );
};

export default DropoutButton;
