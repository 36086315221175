import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { IsEmpty, Map } from "react-lodash";
import Loading from "../loading-animation/loading";

import State from "./components/state";
import moment from "moment";

const Settings = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const _ = require("lodash");

  useEffect(() => {
    setStartDate(setInitialStartDate());
    setEndDate(setInitialEndDate());
  }, []);

  useEffect(() => {
    if (moment(endDate).diff(startDate, "days") < 10) {
      props?.setGrid("h");
      // console.log("Hourgrid");
    } else if (
      moment(endDate).diff(startDate, "days") >= 10 &&
      moment(endDate).diff(startDate, "days") < 90
    ) {
      props?.setGrid("d");
      // console.log("Daygrid");
    } else if (moment(endDate).diff(startDate, "days") >= 60) {
      props?.setGrid("w");
      // console.log("Weekgrid");
    } else if (moment(endDate).diff(startDate, "days") >= 120) {
      props?.setGrid("m");
      // console.log("Monthgird");
    }
  }, [startDate, endDate]);

  const setInitialEndDate = () => {
    var date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  };

  const setInitialStartDate = () => {
    // var date = new Date(new Date().getTime() - 1 * 60 * 60 * 24 * 1000);

    // if (date.getMinutes() >= 30) {
    //   date.setMinutes(30);
    // } else {
    //   date.setMinutes(0);
    // }

    var date = new Date(new Date().getTime() - 1000 * 60 * 60 * 24);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  };

  return (
    <ul className="settings-table">
      <li>
        <div className="settings-container">
          {_.isEmpty(props?.machineInformation) ? (
            <>
              <h1>Please select a machine</h1>
            </>
          ) : props?.currentMachine == "Overview" ? (
            <>
              <h1>Overview</h1>
            </>
          ) : (
            <>
              <h1>{"Machine: " + props.currentMachine}</h1>
              <div className="machine-info-container">
                <h2>Machine information</h2>
                <ul className="machine-info-list">
                  <li>
                    <ul className="machine-info-list-row">
                      <li>
                        {"Type: " +
                          props?.machineInformation?.filter(
                            (m) => m.machineName === props.currentMachine
                          )[0]?.machineType}
                      </li>
                      <li>
                        {"Installation: " +
                          props?.machineInformation
                            ?.filter(
                              (m) => m.machineName === props.currentMachine
                            )[0]
                            ?.machineInstallation?.split(" ")[0]}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="machine-info-list-row">
                      <li>
                        {"State: " +
                          props?.machineInformation?.filter(
                            (m) => m.machineName === props.currentMachine
                          )[0]?.machineState}
                      </li>
                      <li>
                        {"Error: " +
                          props?.machineInformation?.filter(
                            (m) => m.machineName === props.currentMachine
                          )[0]?.currentError}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </>
          )}
          <div className="dataview-settings-container">
            <h2>Dataview settings</h2>
            <ul className="date-selection">
              <li>
                <p>Start date:</p>
                <DatePicker
                  className="date-picker"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  onCalendarClose={() => {
                    props?.setStartDate(startDate);
                  }}
                  // showTimeSelect
                  minDate={
                    new Date(
                      new Date().getTime() - 12 * 30 * 60 * 60 * 24 * 1000
                    )
                  }
                  maxDate={
                    new Date(new Date().getTime() - 1 * 60 * 60 * 24 * 1000)
                  }
                  dateFormat={"dd.MM.yy"}
                  // timeFormat="HH:mm"
                />
              </li>
              <li>
                <p>End date:</p>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  onCalendarClose={() => props?.setEndDate(endDate)}
                  // showTimeSelect
                  minDate={startDate.getTime() + 1 * 60 * 60 * 24 * 1000}
                  maxDate={new Date()}
                  dateFormat={"dd.MM.yy"}
                  // timeFormat="HH:mm"
                />
              </li>
              {/* <li>
                <p>Grid:</p>
                <select
                  id="view-res"
                  onChange={(e) => {
                    // console.log(e.target[e.target.selectedIndex].value);
                    props?.setGrid(e.target[e.target.selectedIndex].value);
                  }}
                  sel="true"
                  defaultValue="h"
                >
                  <option value="m">month</option>
                  <option value="w">week</option>
                  <option value="d">day</option>
                  <option value="h">hour</option>
                </select>
              </li> */}
            </ul>
          </div>
        </div>
      </li>
      <li>
        {/* <div className="image-container"></div> */}
        <div className="version-overview-container">
          {_.isEmpty(props?.machineInformation) ? (
            <></>
          ) : (
            <>
              {/* <h2>Software Versions</h2>
              <ul>
                <li>PrintPreparation: v1.9.23</li>
                <li>PrinserverAnsteuerung: v1.9.23</li>
                <li>ProgrammXY: v1.9.23</li>
                <li>ProgrammZ: v1.9.23</li>
              </ul> */}
            </>
          )}
        </div>
      </li>
    </ul>
  );
};

export default Settings;
