import { Outlet } from "react-router-dom";

import Navbar from "./components/base-components/navbar";
import Footer from "./components/base-components/footer";

import "./layout.css";

const Layout = () => {
  return (
    <main className="App">
      <div className="app-content-container">
        <Navbar />
        <div className="page">
          <Outlet />
        </div>
        <div className="footer-container">
          <Footer elements={["Info", "Contact", "Impressum"]} />
        </div>
      </div>
    </main>
  );
};

export default Layout;
