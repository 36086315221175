import "./style.css";

function Help() {
  return (
    <section className="help-page-container">
      <h1> This should be the help page</h1>
    </section>
  );
}

export default Help;
