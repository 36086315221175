import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "./style.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val, index) {
            if (props?.grid) {
              return index % props?.grid === 0
                ? this.getLabelForValue(val)
                : "";
            } else {
              return index % 2 === 0 ? this.getLabelForValue(val) : "";
            }
            // Hide every 2nd tick label
          },
          color: "black",
        },
      },
    },
  };

  const getLabels = (data) => {
    return data?.map(
      (e) =>
        e.dateTimeDE.getDate() +
        "." +
        (e.dateTimeDE.getMonth() + 1) +
        " " +
        e.dateTimeDE.getHours() +
        ":" +
        e.dateTimeDE.getMinutes()
    );
  };

  const getDataSet = (data) => {
    return data?.map((e) => e?.pieces);
  };

  const machineData = props?.machineData?.sort(function (a, b) {
    return a.dateTimeDE - b.dateTimeDE;
  });

  const labels = getLabels(machineData);

  const dataArray = getDataSet(machineData);

  const data = {
    labels,
    datasets: [
      {
        data: dataArray,
        borderColor: "rgb(63, 135, 193)",
        backgroundColor: "rgba(63, 135, 193, 0.5)",
      },
    ],
  };

  return (
    <div className="line-chart-container">
      {props?.machineData?.length ? (
        <>
          <h2>Production Progress</h2>
          <Line options={options} data={data} className="line-chart-view" />
        </>
      ) : (
        <h1>There is no data to show</h1>
      )}{" "}
    </div>
  );
};

export default LineChart;
