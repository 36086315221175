import "./style.css";

const Loading = () => {
  return (
    <div className="loading-container" id="loading">
      <svg
        version="1.1"
        className="preload-animation"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        // style="enable-background:new 0 0 1000 1000;"
      >
        <g>
          <path
            className="st4 obj obj01"
            d="M513.092,123.066c0,0,4.446,15.068,5.187,23.961s4.446,33.595,4.446,33.595l9.387,15.562
c0,0,4.94,9.387,4.94,15.562c0,6.176,0,115.606,0,115.606s0.247,7.411-6.422,7.411c-3.214,0-15.57,0-30.581,0h-30.681
c-6.67,0-6.423-7.411-6.423-7.411s0-109.43,0-115.606c0-6.175,4.94-15.562,4.94-15.562l9.387-15.562c0,0,3.705-24.702,4.446-33.595
s5.187-23.961,5.187-23.961s11.032,0.011,14.142,0.062L513.092,123.066z"
          ></path>
          <path
            className="st4 obj obj01"
            d="M501.482,122.664l-13.524-0.093c0,0-3.057-1.667-2.964-3.242c0.093-1.575,2.964-2.594,2.964-2.594h11.116
h1.945h11.394c0,0,2.872,1.019,2.964,2.594c0.093,1.575-2.964,3.242-2.964,3.242l-10.56,0.093H501.482z"
          ></path>
        </g>

        <g>
          <polygon
            className="st2 obj obj02"
            points="608.132,354.432 694.681,206.932 693.245,204.541 664.063,187.418 661.276,187.331 574.727,334.831
"
          ></polygon>
          <rect
            x="579.705"
            y="330.07"
            transform="matrix(0.5061 -0.8625 0.8625 0.5061 -12.9116 680.3322)"
            className="st3 obj obj02"
            width="15.693"
            height="42.739"
          ></rect>
        </g>

        <g>
          <path
            className="st7 obj obj03"
            d="M857.906,285.361c-3.815,1.782-212.296,99.173-212.296,99.173s-0.466,7.344-5.09,11.975
c-6.867,6.877,26.846,76.488,35.901,76.938c7.742-0.959,13.409,3.057,13.409,3.057c10.834-5.061,208.907-97.59,210.809-98.478
c1.902-0.889,5.925-15.422,8.262-16.435c1.731-0.75,5.162-2.403,5.162-2.403l-33.695-72.13c0,0-3.76,1.747-5.42,2.523
C872.769,290.597,860.388,284.201,857.906,285.361z"
          ></path>
          <path
            className="st7 obj obj03"
            d="M914.66,360.463l-34.743-74.374c-0.879-1.883-0.059-4.142,1.824-5.022l1.757-0.821
c1.883-0.879,4.142-0.059,5.022,1.824l34.744,74.374c0.879,1.883,0.059,4.142-1.824,5.022l-1.757,0.821
C917.8,363.167,915.54,362.346,914.66,360.463z"
          ></path>
        </g>

        <g>
          <path
            className="st5 obj obj04"
            d="M658.564,569.618c0,0,284.134,59.507,289.152,60.716c4.877,1.175,6.547-0.63,6.547-0.63l13.182-62.779
c0,0-0.259-2.011-5.741-3.21c-5.042-1.103-289.152-60.716-289.152-60.716s-5.917-2.153-6.708,1.398
c-1.305,4.371-12.297,56.717-12.86,61.243C652.299,569.128,658.564,569.618,658.564,569.618z"
          ></path>
          <polygon
            className="st5 obj obj04"
            points="982.119,533.891 964.86,616.639 957.518,615.098 974.903,532.307 	"
          ></polygon>
          <path
            className="st5 obj obj04"
            d="M984.137,604.052c1.792-8.535-4.797-11.107-4.797-11.107l-8.82-1.852l-3.778,19.406l8.026,1.388
C974.769,611.888,982.345,612.588,984.137,604.052z"
          ></path>
          <polygon
            className="st5 obj obj04"
            points="973.907,535.068 919.103,524.451 827.751,513.884 826.974,517.582 915.641,546.597 968.948,558.682"
          ></polygon>
        </g>

        <g>
          <path
            className="st8 obj obj05"
            d="M814.524,777.911c-2.708-3.224-150.733-179.399-150.733-179.399s-8.305,2.368-14.354-0.582
c-8.982-4.381-80.571,57.182-79.379,66.648c2.522,7.572-0.911,14.943-0.911,14.943c7.692,9.155,148.327,176.536,149.678,178.143
c1.351,1.608,18.369,0.103,19.94,2.109c1.163,1.485,3.655,4.366,3.655,4.366l74.412-62.522c0,0-2.66-3.181-3.839-4.584
C811.447,795.191,816.287,780.01,814.524,777.911z"
          ></path>
          <path
            className="st8 obj obj05"
            d="M740.464,865.782l78.005-65.54c1.591-1.337,3.986-1.129,5.323,0.462l1.247,1.485
c1.337,1.591,1.129,3.986-0.462,5.323l-78.005,65.54c-1.591,1.337-3.986,1.129-5.323-0.462l-1.247-1.485
C738.665,869.514,738.873,867.119,740.464,865.782z"
          ></path>
        </g>

        <g>
          <path
            className="st9 obj obj06"
            d="M455.579,880.646h43.022h2.798h43.022l0.215-7.314l-2.151-0.861c0,0-3.227-12.047-5.163-46.035
c-1.936-33.989-2.366-102.611-2.581-108.849c-0.215-6.238,0.645-18.285-2.366-19.791l-0.645-33.558h-31.62h-0.217h-31.62
l-0.645,33.558c-3.012,1.506-2.151,13.552-2.366,19.791c-0.215,6.238-0.645,74.861-2.581,108.849s-5.163,46.035-5.163,46.035
l-2.151,0.861L455.579,880.646z"
          ></path>
          <line
            className="st9 obj obj06"
            x1="543.81"
            y1="872.183"
            x2="458.238"
            y2="872.183"
          ></line>
          <line
            className="st9 obj obj06"
            x1="532.374"
            y1="697.796"
            x2="467.626"
            y2="697.796"
          ></line>
        </g>

        <g>
          <rect
            x="305.869"
            y="685.663"
            transform="matrix(0.5061 -0.8625 0.8625 0.5061 -419.4254 673.9067)"
            className="st10 obj obj07"
            width="145.632"
            height="34.994"
          ></rect>
          <path
            className="st11 obj obj07"
            d="M350.804,783.238c0,0,0.861,0.505,1.366-0.356l1.714-2.922c0,0,0.505-0.861-0.356-1.366l-28.459-16.699
c0,0-0.861-0.505-1.366,0.356l-1.714,2.922c0,0-0.505,0.861,0.356,1.366L350.804,783.238z"
          ></path>
          <path
            className="st11 obj obj07"
            d="M306.222,829.633c0,0,13.774,8.082,21.856-5.692l21.07-35.908l-30.181-17.709l-21.07,35.908
c0,0-8.082,13.774,5.692,21.856L306.222,829.633z"
          ></path>
        </g>

        <g>
          <path
            className="st6 obj obj08"
            d="M119.49,725.256c5.707-2.666,184.439-83.584,184.439-83.584l59.202-28.878
c2.602-1.269,4.019-4.221,3.206-6.999c-0.009-0.03-0.018-0.06-0.027-0.09c-0.873-2.871-10.979-26.466-11.893-28.598
c-0.045-0.105-13.175-26.476-13.175-26.476c-0.886-1.897-3.715-8.292-8.482-6.065c-1.476,0.69-61.825,26.388-61.825,26.388
S95.587,652.211,87.198,656.13c-5.772,2.696-9.796,10.708-8.402,16.843s4.952,13.752,10.478,25.58s8.302,17.072,11.436,21.331
C103.845,724.143,113.783,727.922,119.49,725.256z"
          ></path>
          <path
            className="st6 obj obj08"
            d="M305.297,641.033c0,0-7.706-15.205-17.135-35.389c-9.429-20.184-15.86-35.33-15.86-35.33"
          ></path>
        </g>

        <g>
          <path
            className="st0 obj obj09"
            d="M339.337,442.002c0,0-30.987-6.445-42.843-8.91s-55.865-7.121-64.275-8.589
c-8.409-1.468-42.878-11.448-42.878-11.448s-36.758-7.082-39.722-7.699c-2.964-0.616-12.75,8.593-12.75,8.593l-2.97,14.281
l-0.183,0.879l-0.21,1.008l-0.183,0.879l-2.97,14.281c0,0,5.303,12.347,8.267,12.964c2.964,0.616,39.498,8.777,39.498,8.777
s35.59,4.591,43.887,6.597c8.297,2.007,50.513,15.284,62.369,17.75s42.843,8.91,42.843,8.91s1.606,1.74,4.119-2.236
c2.291-3.624,6.134-22.57,6.795-25.891c0.717-3.309,4.747-22.216,4.091-26.453C341.503,441.047,339.337,442.002,339.337,442.002z"
          ></path>
          <path
            className="st0 obj obj09"
            d="M130.336,412.921l-3.087,14.842l-0.107,0.517l-3.087,14.842c0,0,1.787,6.275,6.299,2.153l3.255-15.651
l0.107-0.517l3.255-15.651C134.477,407.879,130.336,412.921,130.336,412.921z"
          ></path>
          <line
            className="st0 obj obj09"
            x1="140.833"
            y1="458.418"
            x2="151.816"
            y2="405.605"
          ></line>
        </g>

        <g>
          <path
            className="st1 obj obj10"
            d="M304.787,186.987c-16.925-0.752-34.427,5.147-34.427,5.147l-11.061-13.165l-4.117-3.293
c-1.272-1.017-3.894-0.96-4.722-2.362l-1.897-7.122l-5.216-4.595c-1.038-0.914-1.952-1.96-2.72-3.111l-5.619-8.427
c0,0-4.895-4.254-12.216,0.136s-16.789,11.464-16.789,11.464s-8.601,8.107-14.187,14.562s-2.239,12.01-2.239,12.01l7.332,6.988
c1.001,0.954,1.874,2.035,2.596,3.215l3.627,5.93l6.672,3.11c1.239,1.058,0.747,3.616,1.53,5.044l2.534,4.623l11.061,13.165
c0,0-8.827,16.224-11.004,33.025s7.479,24.101,7.479,24.101s79.369,94.462,83.717,99.638c4.349,5.176,9.294,3.2,9.294,3.2
s9.961,13.428,19.095,14.341c24.157,2.414,54.827-25.94,54.827-25.94s33.216-25.324,35.002-49.536
c0.675-9.154-10.835-21.281-10.835-21.281s2.799-4.531-1.55-9.707s-83.717-99.638-83.717-99.638S321.712,187.739,304.787,186.987z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Loading;
