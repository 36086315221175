import "./style.css";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

const OverviewTable = (props) => {
  const GetMachineRow = (latestPieceCount) => {
    try {
      return (
        <li className="overview-machine-row">
          <p href="#">{latestPieceCount?.machineName}</p>
          <p href="#">{numberWithCommas(latestPieceCount?.data[0].pieces)}</p>
          <p href="#">
            {numberWithCommas(getLastProduction(latestPieceCount?.data))}
          </p>
          <p className="last-update" href="#">
            {latestPieceCount?.data[0].dateTimeDE
              .toJSON()
              .replace("T", " ")
              .replace(".000Z", "")}
          </p>
        </li>
      );
    } catch {
      return <></>;
    }
  };

  const getLastProduction = (data) => {
    data.sort(function (a, b) {
      return b.dateTimeDE - a.dateTimeDE;
    });
    return data[0].pieces - data[24].pieces;
  };

  return props?.currentMachine === "Overview" ? (
    <div className="overview-table-container">
      <h1>Production state overview</h1>
      <ul className="overview-table-list">
        <li className="overview-machine-row">
          <h2 href="#">Machine</h2>
          <h2 href="#">Absolute count</h2>
          <h2 href="#">Daily count</h2>
          <h2 className="last-update" href="#">
            Last update
          </h2>
        </li>
        {props?.latestPieceCounts?.map((machine) => GetMachineRow(machine))}
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default OverviewTable;
