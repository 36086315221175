import SideBar from "./components/sidebar/sidebar";
import DropoutButton from "./components/dropout-button/dropout-button";

import "./style.css";

const PageSidebar = (props) => {
  return (
    <div className="page-sidebar-container">
      <div id="sider" className="page-sidebar">
        <SideBar
          elements={props.machines}
          onClick={props.handleMachineListClick}
        />
      </div>
      <DropoutButton
        onClick={() => {
          var width = document.getElementById("sider").style.width;
          if (width !== "0%") {
            document.getElementById("sider").style.width = "0%";
            document.getElementById("in-svg").style.display = "block";
            document.getElementById("out-svg").style.display = "none";
          } else {
            document.getElementById("sider").style.width = "100%";
            document.getElementById("in-svg").style.display = "none";
            document.getElementById("out-svg").style.display = "block";
          }
        }}
      />
    </div>
  );
};

export default PageSidebar;
