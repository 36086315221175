import Settings from "../settings-view/settings";
import PieceCount from "../piececount-view/piececount";
import LineChart from "../linechart/linechart";
import OverviewTable from "../overview-table/overview-table";

import moment from "moment";

import "./style.css";

const DataviewGrid = (props) => {
  return (
    <div className="data-view-grid">
      <div className="data-view-grid-row">
        <div className="data-view-item">
          <Settings
            setStartDate={(date) => {
              props?.setStartDate(date);
            }}
            setEndDate={(date) => {
              props?.setEndDate(date);
            }}
            setGrid={(grid) => {
              props?.setGrid(grid);
            }}
            machineInformation={props?.machineInfo}
            currentMachine={props?.curMachine}
          />
        </div>
      </div>
      <div className="data-view-grid-row">
        <div className="data-view-item">
          <PieceCount
            pieceCountTypeName="Absolute Piece Count"
            machineData={props?.latestPieceCountData}
          />
        </div>
        <div className="data-view-item">
          <PieceCount
            pieceCountTypeName="Production Trend"
            machineData={props?.latestPieceCountData}
            // dataviewGrid={props?.grid}
          />
        </div>
      </div>
      <div className="data-view-grid-row">
        <div className="data-view-item">
          <LineChart
            machineData={props?.data?.filter((d) => {
              // console.log(d);
              return moment(d.dateTimeDE).isSameOrAfter(props?.startDate);
            })}
            dataviewGrid={props?.grid}
          />
        </div>
      </div>
      <div className="data-view-grid-row">
        <div className="data-view-item">
          <OverviewTable
            currentMachine={props?.curMachine}
            latestPieceCounts={props?.individualLatestPieceCounts}
          />
        </div>
      </div>
    </div>
  );
};

export default DataviewGrid;
