import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import RequireAuth from "./components/require-auth";
import Login from "./components/pages/login-new/Login";
import Layout from "./layout";
import Home from "./components/pages/home-new/home";
import Help from "./components/pages/help-page/help";
import Unauthorized from "./components/pages/helper-pages/unauthorized";
import Dashboard from "./components/pages/dashboard-new/dashboard";
import Missing from "./components/pages/helper-pages/missing";
import Loading from "./components/pages/dashboard-new/components/loading-animation/loading";
import Start from "./components/start";
import { DataProvider } from "./context/data-provider";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "loading...";
    setTimeout(() => {
      setLoading(false);
      document.title = "Hinterkopf GmbH | Digistats.";
    }, 4500);
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes*/}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Start />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/" element={<Start />} /> */}
        <Route path="unauthorized" element={<Unauthorized />} />

        {/*Protected Routes*/}
        <Route element={<RequireAuth />}>
          <Route path="/help" element={<Help />} />
          <Route path="/statistics" element={<Dashboard />} />
        </Route>

        {/* Catch remaining */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
