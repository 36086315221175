import "./style.css";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import moment from "moment";

import useAuth from "../../../hooks/useAuth";
import PageSidebar from "./components/page-sidebar/page-sidebar";
import DataviewGrid from "./components/dataview-grid/dataview-grid";

const BASEADRESS = "";

const Dashboard = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [machines, setMachines] = useState([]);
  const [data, setData] = useState([]);
  const [machineInfo, setMachineInfo] = useState([{}]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [grid, setGrid] = useState("h");
  const [curMachine, setCurMachine] = useState("");
  const [latestPieceCount, setLatestPieceCount] = useState();
  const [individualLatestPieceCounts, setIndividualLatestPieceCounts] =
    useState([[{}]]);

  const getOverview = async () => {
    var tempLatestPieceCount = [];
    var tempIndividualLatestPieceCounts = [];
    var tempData = [];
    for (let i = 0; i < machineInfo?.length; i++) {
      var m = (await fetchLatestPieceCount(machineInfo[i].machineName))?.sort(
        function (a, b) {
          return b.dateTimeDE - a.dateTimeDE;
        }
      );

      tempIndividualLatestPieceCounts.push({
        data: m,
        machineName: machineInfo[i].machineName,
      });

      for (let j = 0; j < m.length; j++) {
        if (tempLatestPieceCount.length < m.length)
          tempLatestPieceCount.push({ pieces: m[j].pieces });
        else tempLatestPieceCount[j].pieces += m[j].pieces;
      }

      var d = (
        await fetchData(machineInfo[i].machineName, startDate, endDate, grid)
      )?.sort(function (a, b) {
        return b.dateTimeDE - a.dateTimeDE;
      });

      for (let j = 0; j < d.length; j++) {
        if (tempData.length < d.length)
          tempData.push({ pieces: d[j].pieces, dateTimeDE: d[j].dateTimeDE });
        else {
          tempData[j].pieces += d[j].pieces;
        }
      }
    }

    setData(tempData);
    setLatestPieceCount(tempLatestPieceCount);
    setIndividualLatestPieceCounts(tempIndividualLatestPieceCounts);
  };

  const getMachineDataObjects = (machineData) => {
    return machineData.map((r) => {
      return {
        machineId: r.machineID,
        pieces: r.pieces,
        dateTimeUTC: new Date(r.dateTimeUTC),
        dateTimeDE: new Date(r.dateTimeDE),
      };
    });
  };

  const fetchData = async (machineId, startParam, endParam, grid) => {
    if (machineId) {
      try {
        var req = BASEADRESS + "/data/records?machineId=" + machineId;
        if (startParam && endParam) {
          var start = moment(
            new Date(startParam) - 1 * 1000 * 60 * 60 * 24 - 1000 * 60 * 60
          ).format("YYYY-MM-DD HH:mm:ss");

          var end = moment(endParam).format("YYYY-MM-DD HH:mm:ss");

          req += "&startDate=" + start;
          req += "&endDate=" + end;
        }

        if (grid !== "h") {
          req += "&interval=" + grid;
        }

        var resp = await axios.get(req, {
          headers: {
            Authorization: "Bearer " + auth.accessToken,
          },
          useCredentials: true,
        });
        //setData(getMachineDataObjects(resp?.data));
        return getMachineDataObjects(resp?.data);
      } catch (err) {
        //setData([]);
        return [];
        //console.clear();
      }
    }
  };

  const fetchMachineInfo = async (machineId) => {
    try {
      await axios
        .get(BASEADRESS + "/data/info", {
          headers: {
            Authorization: "Bearer " + auth.accessToken,
          },
          useCredentials: true,
        })
        .then((resp) => {
          setMachineInfo(resp?.data);
        });
    } catch (err) {
      //console.clear();
      if (err.response?.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchLatestPieceCount = async (machineId) => {
    if (machineId) {
      try {
        var req = BASEADRESS + "/data/records/latest?machineId=" + machineId;

        var resp = await axios.get(req, {
          headers: {
            Authorization: "Bearer " + auth.accessToken,
          },
          useCredentials: true,
        });

        return getMachineDataObjects(resp?.data);
      } catch (err) {
        return [];
      }
    }
  };

  const handleMachineListClick = (event) => {
    setCurMachine(event.target.innerText);
  };

  useEffect(() => {
    // fetchMachines();
    fetchMachineInfo();
  }, []);

  useEffect(() => {
    if (curMachine === "Overview") {
      getOverview();
    } else {
      fetchMachineInfo(curMachine);
      fetchData(curMachine, startDate, endDate, grid).then((resp) =>
        setData(resp)
      );
      fetchLatestPieceCount(curMachine).then((resp) => {
        setLatestPieceCount(resp);
      });
    }
  }, [startDate, endDate, curMachine, grid]);

  return (
    <div className="dashboard-container">
      <PageSidebar
        machines={machineInfo}
        handleMachineListClick={handleMachineListClick}
      />
      <DataviewGrid
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        setGrid={setGrid}
        grid={grid}
        machineInfo={machineInfo}
        curMachine={curMachine}
        data={data}
        latestPieceCountData={latestPieceCount}
        individualLatestPieceCounts={individualLatestPieceCounts}
      />
    </div>
  );
};

export default Dashboard;
