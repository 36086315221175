import React, { Component } from "react";

import "./style/style.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer-container">
        <div className="footer-logo">
          <img src="./res/logo.gif" alt="Hinterkopf Logo" />
        </div>
        <div className="footer-link-container">
          <ul className="footer-links">
            {this.props.elements.map((element) => (
              <li key={element} onClick={this.props.onClick}>
                <a href="#">{element}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
