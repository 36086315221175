import React, { Component, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

import "./style/style.css";

const Navbar = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [showNavbarItems, setShowNavbarItems] = useState(false);

  const logout = () => {
    setAuth({});
    navigate("/home");
  };

  useEffect(() => {
    window.innerWidth <= 1500
      ? setShowNavbarItems(false)
      : setShowNavbarItems(true);
  }, []);

  const resizeHandler = () => {
    window.innerWidth <= 1500
      ? setShowNavbarItems(false)
      : setShowNavbarItems(true);
  };

  window.onresize = resizeHandler;

  return (
    <header className="header">
      <div className="logo">
        <img
          id="navbar-logo-desktop"
          src="./res/logo.gif"
          alt="Hinterkopf Logo"
        />
      </div>
      <nav className="nav">
        <button
          className="drop-btn"
          onClick={() => {
            if (window.innerWidth <= 1500) {
              showNavbarItems
                ? setShowNavbarItems(false)
                : setShowNavbarItems(true);
            }
          }}
        >
          <img src="./res/burger.png" />
        </button>
        {showNavbarItems ? (
          <ul className="nav_ul">
            <li id="Home">
              <a
                href="#"
                onClick={() => {
                  if (window.innerWidth <= 1500) setShowNavbarItems(false);
                  navigate("/home");
                }}
              >
                Home
              </a>
            </li>
            <li id="Dashboard">
              <a
                href="#"
                onClick={() => {
                  if (window.innerWidth <= 1500) setShowNavbarItems(false);
                  navigate("/statistics");
                }}
              >
                Dashboard
              </a>
            </li>
            <li id="Help">
              <a
                href="#"
                onClick={() => {
                  if (window.innerWidth <= 1500) setShowNavbarItems(false);
                  navigate("/help");
                }}
              >
                Help
              </a>
            </li>
            {auth.user ? (
              <li id="logut" className="login">
                <a
                  href="#"
                  onClick={() => {
                    if (window.innerWidth <= 500) setShowNavbarItems(false);
                    logout();
                  }}
                >
                  Logout
                </a>
              </li>
            ) : (
              <li id="login" className="login">
                <a
                  href="#"
                  onClick={() => {
                    {
                      if (window.innerWidth <= 1500) setShowNavbarItems(false);
                      navigate("/login");
                    }
                  }}
                >
                  Login
                </a>
              </li>
            )}
          </ul>
        ) : (
          <></>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
